import React from "react";

export default function Ride({
  city,
  date,
  stationCode,
  id,
  mapUrl,
  state,
  stationPath,
  userCode,
}) {
  const closest = stationPath.reduce((a, b) => {
    let aDiff = Math.abs(a - userCode);
    let bDiff = Math.abs(b - userCode);
    if (aDiff === bDiff) {
      return a > b ? a : b;
    } else {
      return bDiff < aDiff ? b : a;
    }
  });
  const distance = +(closest - userCode).toString().replace(/-/, "");
  return (
    <div className={`ride ride-${id}`}>
      <div className="absolute">
        <p> {city} </p>
        <p> {state} </p>
      </div>
      <img src={mapUrl} alt={`${city}-img`} />
      <div className="info">
        <p> Ride Id: {id} </p>
        <p> Origin Station: {stationCode} </p>
        <p> Station_path: {JSON.stringify(stationPath)} </p>
        <p> Date: {date} </p>
        <p> Distance: {distance} </p>
      </div>
    </div>
  );
}
