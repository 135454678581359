import "./sass/style.css";
import "./sass/all.min.css";
import { useEffect, useState } from "react";
import Ride from "./components/Ride";
import Header from "./components/Header";
import Filter from "./components/Filter";
import Option from "./components/Option";

export default function App() {
  const [rides, setRides] = useState("");
  const [clonedRides, setClonedRides] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters] = useState([
    {
      innerText: "Nearest Ride",
    },
    {
      innerText: "Upcoming Ride",
    },
    {
      innerText: "Past Ride",
    },
  ]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [selectedStateValue, setSelectedStateValue] = useState("");
  const [selectedCityValue, setSelectedCityValue] = useState("");
  const [isSelectingFromStates, setIsSelectingFromStates] = useState(false);
  const [isSelectingFromCities, setIsSelectingFromCities] = useState(false);

  function getUserData() {
    fetch("/user")
      .then((res) => res.json())
      .then((data) => setUser(data));
  }

  async function getRidesData() {
    const res = await fetch("/rides");
    const data = await res.json();
    setClonedRides(data);
    setRides(data);
    setIsLoading(false);
  }

  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    getRidesData();
  }, []);

  let clonedArr = [];
  function handleClick(e) {
    setRides(clonedRides);
    const { name } = e.target;
    const allFilters = document.querySelectorAll(".filter");
    allFilters.forEach((filter) => {
      filter.classList.remove("selected");
    });
    e.target.classList.add("selected");
    if (name === "Nearest Ride") {
      sortRidesByDistance();
    } else if (name === "Upcoming Ride") {
      upComingRides();
    } else if (name === "Past Ride") {
      pastRides();
    }
  }

  function sortRidesByDistance() {
    setRides(clonedRides);
    clonedRides.map((ride) => {
      const closest = ride.station_path.reduce((a, b) => {
        let aDiff = Math.abs(a - user.station_code);
        let bDiff = Math.abs(b - user.station_code);
        if (aDiff === bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
      const distance = +(closest - user.station_code)
        .toString()
        .replace(/-/, "");
      clonedArr.push({ ...ride, distance: distance });
      return null;
    });
    setRides(clonedArr.sort((a, b) => a.distance - b.distance));
  }

  function upComingRides() {
    clonedRides.map((ride) => {
      const rideDate = Date.parse(new Date(ride.date));
      const currentDate = new Date().getTime();
      if (rideDate > currentDate) {
        clonedArr.push(ride);
      }
      return null;
    });
    setRides(clonedArr);
  }

  function pastRides() {
    clonedRides.map((ride) => {
      const rideDate = Date.parse(new Date(ride.date));
      const currentDate = new Date().getTime();
      if (rideDate < currentDate) {
        clonedArr.push(ride);
      }
      return null;
    });
    setRides(clonedArr);
  }

  function filterCountries() {
    let cities = [];
    clonedRides.map((ride) => {
      cities.push(ride.city);
      return null;
    });
    let newCities = new Set(cities);
    let uniqueArray = [...newCities];
    setFilteredCities(uniqueArray);
  }
  function filterStates() {
    let states = [];
    clonedRides.map((ride) => {
      states.push(ride.state);
      return null;
    });
    let newStates = new Set(states);
    let uniqueArray = [...newStates];
    setFilteredStates(uniqueArray);
  }

  let cloneArr = [];
  function handleChange(e, name) {
    const { value } = e.target;
    setRides(clonedRides);
    if (value !== "") {
      if (name === "State") {
        setSelectedStateValue(value);
        setIsSelectingFromStates(true);
      } else {
        setSelectedCityValue(value);
        setIsSelectingFromCities(true);
      }
    }
  }

  function filterData() {
    if (isSelectingFromStates) {
      cloneArr = [];
      rides.map((ride) => {
        return ride.state === selectedStateValue ? cloneArr.push(ride) : "";
      });
    } else if (isSelectingFromCities) {
      rides.map((ride) => {
        return ride.city === selectedCityValue ? cloneArr.push(ride) : "";
      });
    } else {
      return;
    }

    if (isSelectingFromStates && isSelectingFromCities) {
      cloneArr = [];
      rides.map((ride) => {
        return ride.city === selectedCityValue &&
          ride.state === selectedStateValue
          ? cloneArr.push(ride)
          : "";
      });
    }
    setRides(cloneArr);
    if (!isSelectingFromStates && !isSelectingFromCities) {
      setRides(clonedRides);
    }
  }

  useEffect(() => {
    filterData();
  }, [selectedStateValue]);

  useEffect(() => {
    filterData();
  }, [selectedCityValue]);

  useEffect(() => {
    filterCountries();
    filterStates();
  }, [rides]);

  function resetSelected() {
    const allFilters = document.querySelectorAll(".filter");
    allFilters.forEach((filter) => {
      filter.classList.remove("selected");
    });
    setRides(clonedRides);
  }

  return (
    <div className="App">
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          <div className="App-container">
            <Header
              name={user.name}
              url={user.url}
              stationCode={user.station_code}
            />

            <div className="filters-container">
              {filters.map((filter, i) => (
                <Filter
                  key={i}
                  innerText={filter.innerText}
                  onClick={handleClick}
                />
              ))}
              <p className="filters-icon">
                <i className="fa-solid fa-bars"></i> Filters
              </p>
            </div>

            <div className="rides-container">
              {rides.length !== 0 ? (
                rides.map((ride, i) => (
                  <Ride
                    key={i}
                    city={ride.city}
                    date={ride.date}
                    stationCode={ride.origin_station_code}
                    id={ride.id}
                    mapUrl={ride.map_url}
                    state={ride.state}
                    stationPath={ride.station_path}
                    userCode={user.station_code}
                  />
                ))
              ) : (
                <h2> No rides to show... </h2>
              )}
            </div>
          </div>
          <div className="options-container">
            <p>Filters</p>
            <button
              className="refresh-btn"
              onClick={resetSelected}
              title="refresh"
            >
              <i className="fa fa-refresh"></i>
            </button>
            <Option
              name="State"
              options={filteredStates}
              onChange={(e) => handleChange(e, "State")}
              selectedValue={selectedStateValue}
            />
            <Option
              name="City"
              options={filteredCities}
              onChange={(e) => handleChange(e, "City")}
              selectedValue={selectedCityValue}
            />
          </div>
        </>
      )}
    </div>
  );
}
