import React from "react";

export default function Input({ name, options, onChange, selectedValue }) {
  return (
    <select name={name} onChange={onChange} defaultValue={name} title={name}>
      <option disabled>{name}</option>
      {options.map((option, i) => (
        <option key={i} value={option} title={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
