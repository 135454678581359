import React from "react";

export default function Header({ name, url, stationCode }) {
  return (
    <div className="header">
      <h1 className="logo"> Edvora </h1>
      <div className="user-info">
        <p> Station Code: {stationCode} </p>
        <h2 className="user-name"> {name} </h2>
        <img src={url} alt={`${name}-img`} />
      </div>
    </div>
  );
}
